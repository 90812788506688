import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useExportLogics = () => {
  const { push } = useRouter();
  const [filters, setFilters] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);
    if (!isOpen) return push({ query: filters });
    setFilters({ ...query, runQuery: false });
    push({ query: {} });
  }, [isOpen]);

  const listener = (isOpen) => setIsOpen(isOpen);

  const { query } = useRouter();

  return { listener, isOpen };
};
