import { useEffect, useRef, useState } from "react";
import ViewportList from "react-viewport-list";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { CheckBox } from "../../../components/Checkbox";
import { TableBodySkeleton } from "../../../components/Shimmer";
import { TableJackSimple } from "../../../components/Table/parent";
import { customSum, windowDimension } from "../../../components/tools";
import { getUserRole } from "../../../contexts/AuthContext";
import { useLayout } from "../../../contexts/Layout/parent";
import { SmartActivityModal } from "../../../modals/smartActivityModal/parent";
import { apiHistoriesTableHeader } from "../constants";
import { useApiHistoryDetailFormatter } from "./item/formatter";
import { ApiHistoriesTableItem } from "./item/parent";
import ActionButton from "./item/actionButton";

export const ApiHistoriesTable = ({
  array,
  loading,
  showAction: showActionProps,
  selectTableProps = {},
  onAction = () => {},
  setData,
  page,
  runQuery,
  listener,
  setListener,
}) => {
  const [tableData, setTableData] = useState(array);
  // styling props
  const borderWidth = 32;

  const { selectedIds, isAllChecked, handleClickAll, handleClickItem } =
    selectTableProps;

  const additionalTHead = !showActionProps && {
    label: <CheckBox isActive={isAllChecked} onClick={handleClickAll} />,
    width: 56,
  };

  const headerTable = apiHistoriesTableHeader(additionalTHead);

  const tableWidth =
    customSum(headerTable.filter((item) => item).map(({ width }) => width)) +
    borderWidth * 2;

  // styling props

  const { isBookKeeper } = getUserRole();

  const hideAction = array.every(({ callback_url }) => !callback_url);

  const showAction =
    !isBookKeeper &&
    showActionProps &&
    !(loading && !array.length) &&
    !hideAction;

  const tbodyRef = useRef();
  const tbodyRefFloat = useRef();

  const [selectedItem, setSelectedItem] = useState(false);
  const { formatter } = useApiHistoryDetailFormatter();

  const [scrollLeft, setScrollLeft] = useState(0);

  const handleScroll = (event) => {
    const { scrollLeft } = event.target;
    setScrollLeft(scrollLeft);
  };

  const ref = useRef();

  useEffect(() => {
    if (!array.length) return;
    if (tableData.length) return;
    setTableData(array);
  }, [array, tableData]);

  useEffect(() => {
    setTableData([]);
  }, [page, runQuery]);

  useEffect(() => {
    if (!listener) return;
    setTableData([]);
    setListener(false);
  }, [listener]);

  return (
    <div style={{ margin: "0px 32px", position: "relative" }}>
      <div style={{ height: 24 }} />
      <div
        style={{
          width: "100%",
          height: "0%",
          backgroundColor: "red",
          position: "absolute",
          zIndex: 2,
        }}
      >
        {showAction && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              width: 80,
              zIndex: 3,
              transition: "all 0.4s linear",
            }}
          >
            <TableJackSimple
              thStyle={{ position: "static" }}
              tableStyle={{ tableLayout: "auto" }}
              tbodyRef={tbodyRefFloat}
              headerTable={[
                {
                  width: "100%",
                  label: "Action",
                  textAlign: "center",
                },
              ]}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              bodyComponent={
                <>
                  {!!tableData.length && (
                    <ViewportList
                      viewportRef={tbodyRefFloat}
                      items={tableData}
                      itemMinSize={100}
                      margin={8}
                      overscan={10}
                    >
                      {(item, index) => {
                        return (
                          <ActionButton
                            item={item}
                            index={index}
                            array={tableData}
                            onAction={onAction}
                            setTableData={setTableData}
                          />
                        );
                      }}
                    </ViewportList>
                  )}
                </>
              }
            />
          </div>
        )}
      </div>
      <div
        style={{ width: "100%", overflowX: "scroll" }}
        onScroll={handleScroll}
        ref={ref}
      >
        <div
          style={{
            position: "relative",
            width: tableWidth,
            borderRight: `${borderWidth}px solid transparent`,
            zIndex: 1,
          }}
        >
          <TableJackSimple
            tableStyle={{ tableLayout: "auto" }}
            headerTable={headerTable}
            tbodyRef={tbodyRef}
            bodyComponent={
              <>
                {!!tableData.length && (
                  <ViewportList
                    viewportRef={tbodyRef}
                    items={tableData}
                    itemMinSize={100}
                    margin={8}
                    overscan={10}
                  >
                    {(item, index) => (
                      <ApiHistoriesTableItem
                        showActionProps={showActionProps}
                        index={index}
                        item={item}
                        key={index}
                        isLast={index === tableData.length - 1}
                        onClick={(item) => setSelectedItem(item)}
                        isActive={selectedIds.includes(item.id)}
                        onClickCheckbox={handleClickItem}
                      />
                    )}
                  </ViewportList>
                )}
                {loading && <TableBodySkeleton columns={10} />}
              </>
            }
          />
          <SmartActivityModal
            data={selectedItem}
            isOpen={Boolean(selectedItem)}
            toggle={() => setSelectedItem(null)}
            setData={setData}
            setTableData={setTableData}
            setListener={setListener}
          />
        </div>
      </div>
    </div>
  );
};
