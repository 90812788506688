import { getCurrency } from "../../../../components/CalculatorInputs";
import { deepRemoveDuplicates } from "../../../../components/tools";

export const isDecimalFunc = (useFormObj) => {
  const { watch } = useFormObj;

  const isDecimal = (name) => {
    const data = watch(name);
    const currency = getCurrency(data);
    const notDecimalCurrs = ["IDR", "JPY"];
    const result = !notDecimalCurrs.includes(currency);
    return result;
  };

  const isSourceDecimal = isDecimal("source_country");
  const isDestinationDecimal = isDecimal("destination_country");

  return { isSourceDecimal, isDestinationDecimal };
};

export const formatterDestinationData = ({ data }) => {
  if (!data) return [];
  const newData = deepRemoveDuplicates(data, "country_iso");
  const upgradeData = newData.map((item, index) => {
    const { country_iso: isoRaw } = item;
    const getData = data.filter(({ country_iso }) => country_iso === isoRaw);
    const tempCurr = [];
    getData.map(({ currency }) => tempCurr.push(currency));
    return {
      ...item,
      details: getData,
      currencyGroup: tempCurr,
    };
  });
  return upgradeData;
};
