import { useRouter } from "next/router";
import { simpleDate, unformatCurrency } from "../../components/tools";
import { queryArray } from "../eWalletComponents/home/main/filter/modal/content/parent";

export const useApiHistoryQueries = () => {
  const { query } = useRouter();
  const {
    searchbox = "",
    confirm_transactions,
    resend_callback,
    from_date,
    to_date,
    specific_amount,
    minimum_amount,
    maximum_amount,
  } = query;
  const statusArr = queryArray(query, "status");
  const banksArr = queryArray(query, "banks");
  const countriesArr = queryArray(query, "countries");
  const productsArr = queryArray(query, "product");
  const currenciesArr = queryArray(query, "currencies");

  const fromUrl = () => {
    if (!from_date) return "";
    return `&q[created_at_gteq]=${simpleDate(from_date)}`;
  };

  const toUrl = () => {
    if (!to_date) return "";
    return `&q[created_at_lteq]=${simpleDate(to_date)}`;
  };

  const confirmTransactionUrl = () => {
    if (!confirm_transactions) return "";
    return `&q[state_eq]=created&q[callback_url_cont]=https`;
  };

  const resendCallbackUrl = () => {
    if (!resend_callback) return "";
    return `&q[state_not_eq]=created&q[callback_url_cont]=https`;
  };

  const statusUrl = statusArr
    .map((status) => {
      const isConfirmed = status == "confirmed";
      const isCanceled = status == "canceled";

      const defaultFunc = (status) => `&q[state_matches_any][]=${status}`;

      if (isConfirmed) return [defaultFunc(status), defaultFunc("submitted")];
      if (isCanceled) return [defaultFunc(status), defaultFunc("rejected")];

      return defaultFunc(status);
    })
    .flat(1)
    .join("");

  const searchBoxUrl = () => {
    if (!searchbox) return "";
    return `&q[reference_id_or_payer_name_or_beneficiary_raw_cont]=${searchbox}`;
  };

  const bankUrl = banksArr
    .map((id) => `&q[payer_id_matches_any][]=${id}`)
    .join("");

  const countryUrl = countriesArr.map(
    (isoCode) => `&q[destination_country_iso_code_matches_any][]=${isoCode}`
  );

  const productUrl = productsArr.map((product) => {
    if (product === "local")
      return `&q[destination_country_iso_code_matches_any][]=IDN`;
    // return `&q[destination_country_iso_code_matches_any][]=INTER`; // temp to exclude inter trx
    return `&q[destination_currency_does_not_match_any][]=IDR`;
  });

  // if filtered by currency instead of by country, use the code below
  const currencyUrl = currenciesArr
    .map((currency) => `&q[destination_currency_matches_any][]=${currency}` ).join("");

  const amountUrl = () => {
    const getNumber = (number) => Number(unformatCurrency(number));
    // const noQuery = !specific_amount && !minimum_amount && !maximum_amount;
    // if (noQuery) return "";

    const amountGTEQ = `&q[amount_gteq]=${getNumber(minimum_amount)}`;
    const amountLTEQ = `&q[amount_lteq]=${getNumber(maximum_amount)}`;

    if (specific_amount) return `&q[amount_eq]=${getNumber(specific_amount)}`;
    if (minimum_amount && maximum_amount) return `${amountGTEQ}${amountLTEQ}`;
    if (minimum_amount) return amountGTEQ;
    if (maximum_amount) return amountLTEQ;
    return "";
    // return amountLTEQ;
  };

  const noInterTrx = !productUrl.length;

  const queries = `${fromUrl()}${toUrl()}${confirmTransactionUrl()}${resendCallbackUrl()}${statusUrl}${bankUrl}${countryUrl}${productUrl}${currencyUrl}${searchBoxUrl()}${amountUrl()}`;

  return { queries, noInterTrx };
};
