import { snakeCase } from "change-case";
import { useConstants } from "../../contexts/ConstantsContext/parent";

export const useApiHistoryFilters = () => {
  const { localTransferBanks } = useConstants();

  const defaultObj = (status) => ({ label: status, value: snakeCase(status) });

  const defaultStatusArr = ["Declined", "Refunded", "Canceled"].map((key) =>
    defaultObj(key)
  );

  return [
    {
      label: "Type",
      icon: "category",
      name: "product",
      type: "status",
      search: true,
      array: [
        {
          label: "International Transfer",
          value: "cross_border",
        },
        {
          label: "Local transfer",
          value: "local",
        },
      ],
    },
    {
      label: "Date",
      name: "date",
      type: "date",
      icon: "calendar-outline",
      queryNames: ["from_date", "to_date"],
    },
    {
      label: "Status",
      name: "status",
      type: "status",
      icon: "loader-outline",
      array: [
        {
          label: "Success",
          value: "completed",
        },
        {
          label: "In progress",
          value: "confirmed",
        },
        {
          label: "Drafted",
          value: "created",
        },
        ...defaultStatusArr,
      ],
    },
    {
      label: "Bank",
      name: "banks",
      type: "status",
      icon: "account-balance",
      array: localTransferBanks,
      search: true,
    },
    {
      label: "Amount",
      type: "amount",
      name: "amount",
      icon: "hash",
      noDirection: true,
      queryNames: [
        "amount_type",
        "specific_amount",
        "minimum_amount",
        "maximum_amount",
      ],
    },
    {
      label: "Country & Currency",
      type: "currencies",
      icon: "globe",
      name: "currencies",
      // search: true,
    },
  ];
};
