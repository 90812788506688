import React, { Fragment } from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { GothamRegular } from "../../../components/Text";
import { removeDuplicates } from "../../../components/tools";
import { useLayout } from "../../../contexts/Layout/parent";

// generated from chatGPT
const createPaginationArray = (totalPage, currentPage, onClick) => {
  const paginationArray = [];
  const maxPagesToShow = 4; // Number of pages to show excluding '...' and current page

  const Icon = ({
    name,
    fill = "black",
    style,
    className,
    onClick = () => {},
  }) => (
    <JackIcons
      name={name}
      style={{ height: 20, width: 20, ...style }}
      className={className}
      fill={fill}
      onClick={onClick}
    />
  );

  const Arrow = ({ isLeft }) => (
    <Icon
      name={isLeft ? "chevron-left" : "chevron-right"}
      style={{ marginRight: 2, marginLeft: 2 }}
      className="hover"
      onClick={() => {
        onClick(isLeft);
      }}
    />
  );

  const addPage = (page) => paginationArray.push(page);
  const addEllipsis = () =>
    paginationArray.push(
      <Icon name="more-horizontal-outline" fill={jackColors.grey90} />
    );
  const addFirstPage = () => addPage(1);
  const addLastPage = () => addPage(totalPage);

  const addPreviousPage = () => currentPage > 1 && addPage(<Arrow isLeft />);
  const addNextPage = () => currentPage < totalPage && addPage(<Arrow />);

  addPreviousPage(); // Add previous page arrow '<'
  addFirstPage(); // Add first page

  let start = currentPage - Math.floor(maxPagesToShow / 2);
  start = Math.max(start, 2);

  if (start > 2) {
    addEllipsis();
  }

  let end = start + maxPagesToShow - 1;
  end = Math.min(end, totalPage - 1);

  for (let i = start; i <= end; i++) {
    addPage(i);
  }

  if (end < totalPage - 1) {
    addEllipsis();
  }

  addLastPage(); // Add last page
  addNextPage(); // Add next page arrow '>'

  return paginationArray;
};
// generated from chatGPT

export const TablePagination = ({ totalPage, page, setPage, loading }) => {
  const { scrollToTop } = useLayout();
  const array = createPaginationArray(
    Number(totalPage),
    Number(page),
    (isLeft) => {
      if (loading) return;
      scrollToTop();
      if (isLeft) return setPage((p) => p - 1);
      return setPage((p) => p + 1);
    }
  );

  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: 32, paddingBottom: 32 }}
    >
      <div className="d-flex" style={{ gap: 8 }}>
        {removeDuplicates(array).map((number, index) => {
          const isNumber = typeof number == "number";
          if (isNumber) {
            const isActive = number == page;
            return (
              <div
                className="hover"
                key={index}
                style={{
                  backgroundColor: isActive
                    ? jackColors.greenB9
                    : "transparent",
                  padding: 2,
                  paddingLeft: 8.5,
                  paddingRight: 8.5,
                  borderRadius: 4,
                }}
                onClick={() => {
                  if (loading) return;
                  scrollToTop();
                  setPage(number);
                }}
              >
                <GothamRegular
                  style={{
                    fontFamily: isActive ? "GothamMedium" : "GothamBook",
                    color: isActive ? jackColors.black34 : jackColors.grey90,
                  }}
                >
                  {number}
                </GothamRegular>
              </div>
            );
          }
          return <Fragment key={index}>{number}</Fragment>;
        })}
      </div>
    </div>
  );
};
