import React, { useState } from "react";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { jackColors } from "../../../../../assets/colors";
import pdfLogo from "../../../../../assets/images/pdf-logo.svg";
import excelLogo from "../../../../../assets/images/excel-logo.svg";
import { CheckBox } from "../../../../../components/Checkbox";

const FileFormatButtons = ({ selected = null, onClick = () => {} }) => {
  const isXLSX = selected === "xlsx";
  const isPDF = selected === "pdf";

  const buttonContents = [
    {
      icon: excelLogo,
      text: "XLSX",
      checked: !!isXLSX,
    },
    {
      icon: pdfLogo,
      text: "PDF",
      checked: !!isPDF,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        width: "100%",
      }}
    >
      <GothamRegular className="font12">File Format</GothamRegular>
      <div
        style={{
          display: "flex",
          gap: 12,
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {buttonContents.map((button) => {
          const value = button.text.toLowerCase();
          const isSelected = value === selected;
          return (
            <div
              style={{
                display: "flex",
                gap: 16,
                padding: "0px 12px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 4,
                border: isSelected ? `solid 1px ${jackColors.neutral900}` : "",
                height: 56,
                cursor: "pointer",
              }}
              onClick={() => onClick(value)}
              className="button-outline"
            >
              <img
                src={button.icon}
                alt="button-logo"
                style={{ height: 24, width: 24, userSelect: "none" }}
              />
              <GothamMedium
                style={{
                  color: isSelected
                    ? jackColors.neutral900
                    : jackColors.neutral700,
                  width: 76,
                  userSelect: "none",
                }}
                className="font12"
              >
                {button.text}
              </GothamMedium>
              <div style={{ padding: 10 }}>
                <CheckBox type="Circle" isActive={button.checked} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FileFormatButtons;
