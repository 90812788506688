export const apiHistoriesTableHeader = (obj) => [
  obj,
  // {
  //   label: "Created At",
  //   width: 105,
  //   textAlign: "left",
  // },
  // {
  //   label: "Last Update",
  //   width: 105,
  //   textAlign: "left",
  // },
  // { label: "Reference", width: 280, textAlign: "left" },
  // { label: "Status", width: 180, textAlign: "left" },
  // { label: "Sender", width: 180, textAlign: "left" },
  // { label: "Recipient", width: 180, textAlign: "left" },
  // { label: "Bank Name", width: 180, textAlign: "left" },
  // {
  //   label: "Bank Account Number",
  //   width: 180,
  //   textAlign: "left",
  // },
  // {
  //   label: "Transaction Notes",
  //   width: 300,
  //   textAlign: "left",
  // },
  // {
  //   label: "Amount",
  //   width: 148,
  //   textAlign: "right",
  // },
  // { label: "Fee", width: 120, textAlign: "right" },
  // {
  //   label: "Total Payment",
  //   width: 148,
  //   textAlign: "right",
  // },
  // updated header below
  {
    label: "Created At",
    width: 104,
    textAlign: "left",
  },
  {
    label: "Completed at",
    width: 104,
    textAlign: "left",
  },
  { label: "Transaction ID", width: 320, textAlign: "left" },
  { label: "Status", width: 144, textAlign: "left" },
  { label: "Sender", width: 240, textAlign: "left" },
  { label: "Recipient", width: 240, textAlign: "left" },
  {
    label: "Amount",
    width: 160,
    textAlign: "right",
  },
  { label: "Handling Fee", width: 160, textAlign: "right" },
  {
    label: "Total Payment",
    width: 160,
    textAlign: "right",
  },
  !obj && { width: 60 },
];
