import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../components/Modals";
import { simpleDate } from "../../../components/tools";
import { useDownload } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { TransactionExportModal } from "../../transactionsComponents/transactions/export/modal";
import { queryDate } from "../../transactionsComponents/transactions/filters/buttons/dropdowns/date/logics";
import { useExportLogics } from "../../transactionsComponents/transactions/filters/logics";
import { DEMO_CLICK_EXPORT_REPORT } from "../../productDemoComponents/constants/events";

export const ApiHistoryExportButton = () => {
  const { isOpen, toggle } = useModalHook();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { query } = useRouter();
  const getDate = (queryName) => simpleDate(queryDate(query, queryName));
  const from = getDate("from_date");
  const to = getDate("to_date");
  const { listener } = useExportLogics();

  useEffect(() => listener(isOpen), [isOpen]);

  const { handleDownload, loading } = useDownload();

  const toggleModal = () => {
    eventsTracker(DEMO_CLICK_EXPORT_REPORT, { page_title: "transaction" });
    setIsModalOpen((p) => !p);
  };

  const onSubmit = (toggle) =>
    handleDownload({
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      url: `/prints/disbursement_report?per_page=10000&q[created_at_gteq]=${from}&q[created_at_lteq]=${to}`,
      fileName: `Jack-Export-API-History-${from}-to-${to}.xlsx`,
      afterSuccess: () => {
        toggle();
        eventsTracker("download_export_history_api_disbursement");
      },
    });

  return (
    <>
      <ButtonJack
        isSmall
        type="outline"
        onClick={toggleModal}
        leftIcon={<JackIcons name="addchart" fill={jackColors.black34} />}
      >
        Export
      </ButtonJack>
      <TransactionExportModal
        toggle={toggleModal}
        isOpen={isModalOpen}
        title="Export API History"
        msg="Choose the date range for API transactions you want to export."
        noFilter
        onSubmit={onSubmit}
        loading={loading}
      />
    </>
  );
};
