import { forEach, get, isEmpty, lowerCase, pickBy } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import { SummarySheet } from "../../components/SummarySheet/parent";
import { GothamMedium } from "../../components/Text";
import {
  deepRemoveDuplicates,
  newCalculatorItemsPreparer,
} from "../../components/tools";
import { CloseHeader } from "../../contexts/Layout/buttons/close";
import { useLayout } from "../../contexts/Layout/parent";
import { apiBusiness, fetch } from "../../tools/api";
import { TransactionsFilters } from "../transactionsComponents/transactions/filters/parent";
import {
  useApiHistoriesActions,
  useOnActionApiHistories,
} from "./buttons/logics";
import { ApiHistoriesButtons } from "./buttons/parent";
import { useApiHistoryFilters } from "./filters";
import { useApiHistoryQueries } from "./query";
import { TablePagination } from "./table/paginate";
import { ApiHistoriesTable } from "./table/parent";
import { EmptyList } from "../transactionsComponents/transactions/empty";
import { useCalculatorOptions } from "../../components/CalculatorInputs";
import { formatterDestinationData } from "../crossBorder/single/calculator/logic";

const ApiDisbursementPageComponent = ({ transactionPage = false }) => {
  const [page, setPage] = useState(1);
  const [listener, setListener] = useState(false);
  const { queries, noInterTrx } = useApiHistoryQueries();

  const noQuery = isEmpty(queries); // temp to exclude inter trx

  const {
    data: dataRaw,
    loading: loadingData,
    refetch,
    setData,
  } = fetch({
    url: `/disbursements?page=${page}&per_page=15${queries}${
      /* noInterTrx ? "&q[destination_country_iso_code_matches_any][]=IDN" :  */ ""
    }`,
    formatter: (res) => {
      return {
        array: deepRemoveDuplicates(res?.data || [], "id"),
        ...res,
      };
    },
    woInit: true,
    defaultValue: {},
  });
  const { total_page, array: arrayRaw = [] } = dataRaw || {};

  const { query, push } = useRouter();
  const { runQuery } = query;

  const toggleRunQuery = (obj) => {
    push({ query: { ...obj, runQuery: false } });
    push({ query: { ...obj, runQuery: true } });
  };

  const { clientWidth } = useLayout();
  const cleanUpFunction = () => {
    const resends = Object.keys(localStorage);
    const asd = (resends || []).filter((key) => key.includes("resend"));
    (asd || []).forEach((item) => {
      localStorage.removeItem(item);
    });
  };

  const { loading: loadingIso, destinationData } = useCalculatorOptions(true);
  const dataFormatted = formatterDestinationData({ data: destinationData });

  const array = arrayRaw?.map((item) => {
    const foundIso = dataFormatted.find(
      (country) => country?.country_iso_3 == item?.destination?.country_iso_code
    );
    const destination = item?.destination
    return { ...item, destination: {
      ...destination, 
      iso: foundIso?.country_iso,
      country_name: foundIso?.country_name
    } };
  });

  useEffect(() => {
    setData((prev) => {
      if (isEmpty(prev)) return {};
      return { ...prev, array: [] };
    });
    refetch();
  }, [page]);

  useEffect(() => {
    const isRunQuery = runQuery == "true";
    if (!isRunQuery) return;
    setData({});
    if (page == 1) return refetch();
    setPage(1);
  }, [runQuery]);

  useEffect(() => {
    cleanUpFunction();
  }, []);
  // refetch master

  const filters = useApiHistoryFilters();

  // styling props
  const borderWidth = 32;
  const actualWidth = clientWidth - borderWidth * 2;
  // styling props

  // action props
  const { isActiveAction, activeActionObj, isResend } =
    useApiHistoriesActions();

  const {
    loadingAction,
    handleAction,
    selectTableProps,
    confirmationModalComponent,
  } = useOnActionApiHistories({ array, setData, setListener });

  const { selectedIds, handleRemoveAllId } = selectTableProps;

  const resetAction = () => {
    handleRemoveAllId();
    const obj = pickBy(query, (_, key) => key != activeActionObj.value);
    toggleRunQuery(obj);
  };
  // action props
  
  const { onClose } = useLayout();

  const loading = loadingData || loadingIso;

  return (
    <div>
      <div
        style={{
          position: "relative",
          height: transactionPage ? 135 : 260,
          padding: "32px 0px",
        }}
      >
        {transactionPage ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              transition: "all 0.4s linear",
              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                margin: "32px 32px",
              }}
            >
              <TransactionsFilters
                filters={filters}
                activeFilters={["product", "date", "status", "banks", "amount", "currencies"]}
                // searchPlaceholder="Search transaction ID, name, or bank"
                isApi
                customComponent={
                  <div className="d-flex">
                    <ApiHistoriesButtons noExport reset={resetAction} />
                  </div>
                }
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              transition: "all 0.4s linear",
            }}
          >
            <CloseHeader onClose={onClose} />
            <div style={{ margin: "0px 32px" }}>
              <div
                className="d-flex"
                style={{
                  gap: 16,
                  width: actualWidth,
                  transition: "all 0.4s linear",
                }}
              >
                <GothamMedium className="font24">API History</GothamMedium>
                <ApiHistoriesButtons reset={resetAction} />
              </div>
              <div
                className="w-100"
                style={{
                  marginTop: 63,
                  marginBottom: 32,
                  height: 1,
                  backgroundColor: jackColors.greyE6,
                }}
              />
              <TransactionsFilters
                filters={filters}
                activeFilters={["date", "status", "banks", "amount"]}
                searchPlaceholder="Search transaction ID, name, or bank"
              />
            </div>
          </div>
        )}
      </div>
      {!array.length && !loading ? (
        <EmptyList />
      ) : (
        <>
          <ApiHistoriesTable
            array={array}
            loading={loading}
            showAction={!isActiveAction}
            selectTableProps={selectTableProps}
            onAction={handleAction}
            setData={setData}
            page={page}
            runQuery={runQuery}
            listener={listener}
            setListener={setListener}
          />
          <TablePagination
            totalPage={total_page}
            page={page}
            setPage={setPage}
            loading={loading}
          />
        </>
      )}

      {confirmationModalComponent}
      {isActiveAction && (
        <div style={{ width: actualWidth, position: "relative" }}>
          <SummarySheet
            wSidebar
            array={[
              {
                title: "Selected Transaction",
                msg: `${selectedIds.length} Transaction(s)`,
              },
            ]}
            buttons={[
              <ButtonJack
                leftIcon={<JackIcons name="arrow-back-outline" />}
                type="borderless"
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid white",
                  color: "white",
                }}
                onClick={resetAction}
              >
                Cancel
              </ButtonJack>,
              <ButtonJack
                disabled={!selectedIds.length}
                leftIcon={
                  <JackIcons
                    name={activeActionObj.icon}
                    fill={
                      selectedIds.length || loadingAction
                        ? jackColors.black34
                        : jackColors.greyBB
                    }
                  />
                }
                isLoading={loadingAction}
                onClick={() => handleAction(selectedIds)}
              >
                {isResend ? "Resend Callback" : "Submit Transaction"}
              </ButtonJack>,
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default ApiDisbursementPageComponent;
