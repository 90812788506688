import { useRouter } from "next/router";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { DropdownButtonJack } from "../../../components/ButtonsJack/dropdown";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { useGetAuth, getUserRole } from "../../../contexts/AuthContext";
import { ApiHistoryExportButton } from "./export";
import { useApiHistoriesActions } from "./logics";

export const ApiHistoriesButtons = ({ reset, noExport = false }) => {
  const { push, query } = useRouter();
  const { isActiveAction, activeActionObj, actions } = useApiHistoriesActions();
  const { isBookKeeper } = getUserRole();

  const { icon, label } = activeActionObj;

  const handleClick = (val) => {
    push({ query: { ...query, [val]: true, runQuery: false } });
    push({ query: { ...query, [val]: true, runQuery: true } });
  };

  if (isActiveAction)
    return (
      <ButtonJack
        leftIcon={
          <JackIcons
            name={icon}
            fill={jackColors.grey6C}
            style={{ height: 16, width: 16 }}
          />
        }
        rightIcon={
          <JackIcons
            name={"close"}
            fill={jackColors.grey6C}
            style={{ height: 16, width: 16 }}
          />
        }
        isBullet
        type="outline"
        onClick={reset}
      >
        {label}
      </ButtonJack>
    );

  if (isBookKeeper) return <ApiHistoryExportButton />;

  return (
    <>
      {!noExport && <ApiHistoryExportButton />}
      <DropdownButtonJack
        onClick={handleClick}
        leftIcon={
          <JackIcons
            name="bulkActions"
            fill={jackColors.black34}
            style={{ height: 20, width: 20, marginBottom: 2 }}
          />
        }
        array={actions}
        dropdownStyle={{ left: -105, zIndex: 10 }}
      >
        Bulk Actions
      </DropdownButtonJack>
    </>
  );
};
