import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import {
  RightModal,
  useSmoothRightModal,
} from "../../../../components/Modals/RightModal/parent";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { aDay } from "../../../../components/tools";
import { DateInputQuery } from "../filters/buttons/dropdowns/date/input";
import { queryDate } from "../filters/buttons/dropdowns/date/logics";
import { TransactionFilterButtons } from "../filters/buttons/parent";
import { useTransactionFiltersArray } from "../logics/filterArray";
import { Banner } from "../../../../components/Banner";
import { MissingCategoryBanner } from "../../../categoryComponents/general/components";
import { useCategoryFlag } from "../../../categoryComponents/general/hooks";
import FileFormatButtons from "../filters/buttons/fileFormatButtons";

const Screen = ({
  title = "Export from all source account",
  msg = "You can export all transaction or filter them based on your needs",
  noFilter = false,
  loading,
  onSubmit,
  missingCategoryCount = 0,
}) => {
  const [activeFilters, setActiveFilters] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState(null);

  const { isRequiredCategory, isNotRequiredCategory } = useCategoryFlag();
  const isActiveCategory = isRequiredCategory || isNotRequiredCategory;

  const handleClick = (value) => {
    if (!value) return setActiveFilters([]);
    setActiveFilters((prev) => [...prev, value]);
  };

  const handleSelect = (format) => {
    if (selectedFormat === format) return setSelectedFormat(null);
    return setSelectedFormat(format);
  };

  const filters = useTransactionFiltersArray();
  const filteredFilters = filters.filter((item) => item?.name != "date");

  const filterProps = {
    activeFilters,
    filters: filteredFilters,
    onClick: handleClick,
  };

  const { query } = useRouter();

  const limiter = (value, isAdd) => {
    if (!value) return null;
    const selectedDate = new Date(value).getTime();
    const aMonth = aDay * 30;

    const number = isAdd ? selectedDate + aMonth : selectedDate - aMonth;
    return new Date(number);
  };

  const minDate = queryDate(query, "from_date");
  const maxDate = queryDate(query, "to_date");

  const disabled = !(minDate && maxDate) || !selectedFormat;

  useEffect(() => {
    if (noFilter) return;
    setSelectedFormat("xlsx");
  }, []);

  return (
    <div
      style={{ overflowX: "hidden", height: "100%", flexDirection: "column" }}
      className="d-flex justify-content-between"
    >
      <div>
        <GothamMedium
          className="font24 mb-2"
          style={{ color: jackColors.black34 }}
        >
          {title}
        </GothamMedium>
        <GothamRegular style={{ color: jackColors.grey6C }}>
          {msg}
        </GothamRegular>
        {!noFilter && (
          <>
            <GothamMedium className="font12" style={{ marginTop: 32 }}>
              Selected filter
            </GothamMedium>
            <TransactionFilterButtons {...filterProps} isModal />
          </>
        )}
        <div style={{ height: 32 }} />

        {noFilter && (
          <>
            <FileFormatButtons
              selected={selectedFormat}
              setSelected={setSelectedFormat}
              onClick={handleSelect}
            />
            <div style={{ height: 32 }} />
          </>
        )}

        <DateInputQuery
          dateFormat="dd/MM/yy"
          queryName="from_date"
          label="Start Date"
          maxDate={maxDate}
          placeholder="01/01/2022"
          minDate={limiter(maxDate, false)}
          customStyle={{ margin: 0 }}
        />
        <div style={{ height: 32 }} />
        <DateInputQuery
          dateFormat="dd/MM/yy"
          queryName="to_date"
          label="End Date"
          minDate={minDate}
          placeholder="31/01/2022"
          maxDate={limiter(minDate, true)}
          customStyle={{ margin: 0 }}
        />
      </div>
      <div className="w-100">
        {isActiveCategory && (
          <MissingCategoryBanner count={missingCategoryCount} />
        )}
        <div
          className="w-100"
          style={{ borderTop: `1px solid ${jackColors.greyE6}` }}
        >
          <div style={{ height: 20 }} />
          <ButtonJack
            className="w-100"
            disabled={disabled}
            isLoading={loading}
            onClick={() => onSubmit(selectedFormat)}
          >
            <div className="d-flex align-items-center">
              Export
              <JackIcons
                name="download-outline"
                fill={
                  disabled || loading ? jackColors.greyBB : jackColors.black34
                }
                style={{ height: 20, width: 20 }}
                className="ml-2"
              />
            </div>
          </ButtonJack>
        </div>
      </div>
    </div>
  );
};

export const TransactionExportModal = ({
  isOpen,
  toggle,
  msg,
  noFilter,
  title,
  onSubmit,
  loading,
  missingCategoryCount = 0,
}) => {
  const { toListener, toToggle } = useSmoothRightModal();
  return (
    <RightModal
      listener={toListener}
      isOpen={isOpen}
      toggle={toggle}
      array={[
        <Screen
          msg={msg}
          noFilter={noFilter}
          title={title}
          loading={loading}
          onSubmit={(format) => onSubmit(toToggle, format)}
          missingCategoryCount={missingCategoryCount}
        />,
      ]}
    />
  );
};
