import { format } from "date-fns";
import { get, startCase } from "lodash";
import { useState } from "react";
import { colors, jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { Avatar } from "../../../../components/Avatar";
import { CheckBox } from "../../../../components/Checkbox";
import { StatusAccountIconJack } from "../../../../components/Status/JackStatusIcon";
import { StatusLabelJack } from "../../../../components/StatusLabel";
import { SubTextTableComponent } from "../../../../components/Table/components";
import { GothamRegular } from "../../../../components/Text";
import CustomTooltip from "../../../../components/Tooltip";
import { TransitionTr } from "../../../../components/Transition";
import { GetFlag } from "../../../../components/inputs";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { SubTextComponent } from "../../../bankAccountSettingComponents/tools/helpers";
import { useApiHistoryDetailFormatter } from "./formatter";

export const ApiHistoriesTableItem = ({
  item,
  onClick,
  onClickCheckbox,
  isActive,
  index,
  showActionProps,
  isLast,
}) => {
  const { formatter } = useApiHistoryDetailFormatter();
  const {
    created_at,
    updated_at,
    reference_id,
    notes,
    id,
    bank,
    account_number,
    senderName,
    beneName,
    currAmount,
    currFee,
    totalAmount,
    status,
    transaction_logo,
    beneficiary,
    destination 
  } = formatter(item);
  
  const isOnProgress =
    status === "on_progress" ||
    status === "created" ||
    status === "Insufficient balance";

  const [hovered, setHovered] = useState(false);

  const { localTransferBanks } = useConstants();
  const payer_name = item?.payer_name;
  const transactionTitle =
    transaction_logo === "local_single"
      ? "Local Transfer API"
      : "International Transfer API";

  const { successSnackBar } = ToasterHook();

  const country = get(beneficiary, 'country', "")
  const countryFallback = get(destination, 'country_name', "")
  const currency = get(destination, 'currency', "")
  const iso = get(destination, 'iso', "")

  const getRecipientTransferInfo = (transactionType) => {
    if (transactionType === "single") return `${currency} - ${startCase((country || countryFallback).toLowerCase())}`;
    const mainBankLabel =
      localTransferBanks?.find(({ bank_name }) => bank_name === payer_name)
        ?.label || payer_name;
    const mainAccountNumber = item?.beneficiary?.account || "Account Number";
    return `${mainBankLabel} - ${mainAccountNumber}`;
  };

  const time = (rawDate) => {
    const date = format(new Date(rawDate), "d/MM/yy");
    const hour = format(new Date(rawDate), "HH:mm");
    return (
      <SubTextTableComponent
        text={date}
        subText={hour}
        subTextStyleAux={{ fontSize: "12px", lineHeight: "16px" }}
        divStyle={{ height: 36 }}
      />
    );
  };

  const curr = (amount) => (
    <GothamRegular style={{ textAlign: "right" }}>{amount}</GothamRegular>
  );

  const OverflowTd = ({
    children,
    aboveChildren,
    belowChildren,
    tdStyle,
    tdClass,
    textStyle,
    mouseEnter = () => {},
    mouseLeave = () => {},
  }) => (
    <td
      style={tdStyle}
      className={tdClass}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      {aboveChildren}
      <GothamRegular
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxWidth: "100%",
          ...textStyle,
        }}
      >
        {children}
      </GothamRegular>
      {belowChildren}
    </td>
  );

  const HoverComponent = ({
    hoverComponent = <></>,
    hoverText = "Hovered!",
  }) => {
    return (
      <CustomTooltip
        customId={`tooltip-${index}`}
        innerClassName="editorTooltip"
        popperClassName="white-arrow"
        placement="top"
        text={
          <div
            style={{
              width: "fit-content",
              height: "auto",
              backgroundColor: "rgba(255,255,255,1) !important",
              opacity: "1 !important",
              zIndex: 200,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              textAlign: "center",
              padding: 0,
            }}
          >
            <GothamRegular
              className="font12"
              style={{ color: colors.neutral800, lineHeight: "16px" }}
            >
              {hoverText}
            </GothamRegular>
          </div>
        }
      >
        {hoverComponent}
      </CustomTooltip>
    );
  };

  return (
    <TransitionTr
      counter={index}
      isQuick
      perPage={15}
      key={index}
      onClick={() => onClick({ ...item, category: "api" })}
      style={{
        borderWidth: isLast ? 0 : 1,
        maxHeight: 65,
        height: 65,
      }}
    >
      {!showActionProps && (
        <td>
          <CheckBox
            isActive={isActive}
            onClick={(e) => {
              if (!e) return;
              e.preventDefault();
              e.stopPropagation();
              onClickCheckbox(id, isActive);
            }}
          />
        </td>
      )}
      <td style={{ maxHeight: 64 }}>{time(created_at)}</td>
      <td style={{ maxHeight: 64 }}>{isOnProgress ? "-" : time(updated_at)}</td>
      <OverflowTd
        aboveChildren={<StatusAccountIconJack state={transaction_logo} />}
        belowChildren={
          hovered && (
            <div style={{ marginLeft: 0 }}>
              <HoverComponent
                hoverComponent={
                  <div
                    className="d-flex justify-content-center align-items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigator.clipboard.writeText(reference_id);
                      successSnackBar({
                        msg: "Transaction ID Copied",
                        showClose: true,
                      });
                    }}
                  >
                    <JackIcons
                      name="file-copy"
                      fill={jackColors.neutral800}
                      style={{ height: 20, width: 20 }}
                    />
                  </div>
                }
                hoverText="Copy Transaction ID"
              />
            </div>
          )
        }
        tdClass="d-flex align-items-center"
        tdStyle={{ gap: 16, maxWidth: 320, maxHeight: 64 }}
        mouseEnter={() => setHovered(true)}
        mouseLeave={() => setHovered(false)}
      >
        <SubTextComponent
          text={transactionTitle}
          customTextStyle={{ width: 120 }}
          subText={reference_id}
          customSubStyle={{
            fontSize: 12,
            width: 170,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 170,
          }}
        />
      </OverflowTd>
      <td style={{ maxHeight: 64, height: 64 }}>
        <StatusLabelJack status={status} isOutline />
      </td>
      <OverflowTd>{senderName}</OverflowTd>
      <OverflowTd
        aboveChildren={
          <AvatarWithTextAndSubtext
            mainText={beneName}
            subText={getRecipientTransferInfo(transaction_logo)}
            iso={iso}
          />
        }
      />
      {/* <OverflowTd>{bank}</OverflowTd> */}
      {/* <OverflowTd>{account_number}</OverflowTd> */}
      {/* <OverflowTd>{notes || "-"}</OverflowTd> */}
      <OverflowTd>{curr(currAmount)}</OverflowTd>
      <OverflowTd>{curr(currFee)}</OverflowTd>
      <OverflowTd>{curr(totalAmount)}</OverflowTd>
      <td style={{ maxHeight: 64 }}></td>
    </TransitionTr>
  );
};

export const AvatarWithTextAndSubtext = ({
  containerStyle,
  mainText,
  subText,
  noPadding = false,
  iso = "",
}) => {
  const recipientStyle = {
    border: "none",
    fontSize: 14,
    maxHeight: 64,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    gap: 16,
    backgroundColor: "transparent",
    color: "#343434",
    width: 240,
    justifyContent: "flex-start",
    ...containerStyle,
  };

  return (
    <div style={recipientStyle}>
      <div style={{position: "relative"}}>
        <Avatar name={mainText} size="medium" />
        {!!iso && <GetFlag
          iso={iso}
          style={{
            marginRight: 12,
            width: 16,
            height: 16,
            position: "absolute",
            bottom: -5,
            right: -18
          }}
        />}
      </div>
      <div style={{ width: "80%" }}>
        <SubTextComponent
          text={mainText}
          subText={subText}
          customSubStyle={{ fontSize: 12 }}
        />
      </div>
    </div>
  );
};
