import React from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { GothamMedium } from "../../../components/Text";

export const EmptyList = () => (
  <div style={{ padding: 40 }} className="d-flex justify-content-center">
    <div className="w-100">
      <JackIcons
        name="archive"
        style={{ height: 60, width: 60 }}
        fill={jackColors.black34}
      />
      <GothamMedium
        style={{
          textAlign: "center",
          color: jackColors.black34,
          marginTop: 16,
        }}
      >
        There’s no transaction yet
      </GothamMedium>
    </div>
  </div>
);
